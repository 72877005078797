.icon {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 1rem 1rem 0;
}

.tile .tooltipText {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: -5.25rem;
    margin-top: -2.4rem;
    width: 6rem;
  }
  
  .tile .tooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tile:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }