.photo {
    height: 250px;
    width: 250px;
    border-radius: 125px;
    margin: 0 auto;
}

.home {
    display: flex;
}

.homeElement {
    margin: auto;
}

.homeLink {
    color: #4285F4;
}

.description {
    margin: auto 0 auto 5rem;
}

@media(max-width: 62.5em) {
    .description {
        margin: auto 0 auto 1rem;
    }
}

@media(max-width: 43.75em) {
    .home {
        display: block;
    }
    .photo {
        height: 150px;
        width: 150px;
        display: block;
        margin-bottom: 2rem;
    }
}